import { post } from './http'

export function authorGet(params) {
  return post('/api/author/get.json', params)
}

export function authorList(params) {
  return post('/api/author/list.json', params)
}

export function authorReputations(params) {
  return post('/api/author/reputations.json', params)
}

export function authorSetReputation(params) {
  return post('/api/author/setReputation.json', params)
}

export function authorSetProfile(params) {
  return post('/api/author/setProfile.json', params)
}