import { post } from './http'

export function postGet(params) {
  return post('/api/post/get.json', params)
}

export function postList(params) {
  return post('/api/post/list.json', params)
}

export function postAudit(params) {
  return post('/api/post/audit.json', params)
}

export function postAuditByAuthor(params) {
  return post('/api/post/auditByAuthor.json', params)
}