<template >
  <div translate="no">
  
    <div class="d-flex justify-space-between align-center text-body-2 mb-2">
        <div class="d-flex justify-start align-center">

            <v-btn icon fab x-small color="primary" outlined  @click="getPostList" class="mr-4">
              <v-icon size="14" >{{icons.mdiRefresh}}</v-icon>
            </v-btn>

            <v-switch v-model="params.auditStatus"
              class="text-caption ml-2"
              :true-value="1"
              :false-value="0"
              flat
              dense
              inset
              hide-details
              @change="onAuditStatusChange"
            ></v-switch>

            <v-btn-toggle
              v-if="(params.auditStatus == 1)"
              v-model="params.featured"
              mandatory
              hide-details
              class="ml-1"
              @change="reloadPostList"
            >
              <v-btn x-small icon depressed :value="1">
                <v-icon color="success">{{ icons.mdiEmoticonHappy }}</v-icon>
              </v-btn>

              <v-btn x-small icon depressed :value="-1">
                <v-icon color="error">{{ icons.mdiEmoticonDead }}</v-icon>
              </v-btn>

              <v-btn x-small icon depressed :value="0">
                <v-icon color="primary">{{ icons.mdiEmoticonNeutral }}</v-icon>
              </v-btn>
          </v-btn-toggle>

          <v-select v-if="languageOptions"
            v-model="params.language"
            placeholder="LANGUAGE"
            :items="languageOptions"
            item-text="name"
            item-value="code"
            dense
            hide-details
            class="ml-2 language-select"
            @change="reloadPostList"
        ></v-select>
        </div>

       

        <span class="text--disabled primary--text">
          {{totalCount}} 
        </span>
    </div>

    <v-card class="pb-10">

      <v-row class="px-1 mt-2">
        <v-col cols="12" md="5" sm="6" xs="12">
          <div class="d-flex align-center justify-start ">
              <v-btn depressed text x-small color="default" class="mt-1 text-caption" @click="changeDate('prev')">
                <v-icon >{{ icons.mdiChevronLeft }}</v-icon>
              </v-btn>
              <span class="text-caption secondary--text ml-2">
                <v-menu
                  ref="menuref"
                  v-model="startTimeMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                    v-model="params.startDate"
                      placeholder="Start Date"
                      persistent-hint
                      v-bind="attrs"
                      hide-details
                      readonly
                      dense
                      clearable
                      v-on="on"
                      class="date-picker"
                      @change="reloadPostList"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="params.startDate"
                    no-title
                    color="primary"
                    :max="params.endDate || new Date().toISOString().substr(0, 10)"
                    @input="startTimeMenu = false"
                    @change="reloadPostList"
                  ></v-date-picker>
                </v-menu>
              </span>
              <span class="ml-2">
                <v-menu
                  ref="menuref"
                  v-model="endTimeMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="params.endDate"
                      placeholder="End Date"
                      persistent-hint
                      readonly
                      clearable
                      dense
                      hide-details
                      v-bind="attrs"
                      v-on="on"
                      @change="reloadPostList"
                    ></v-text-field>
                  </template>

                  <v-date-picker
                    v-model="params.endDate"
                    no-title
                    color="primary"
                    :min="params.startDate"
                    @input="endTimeMenu = false"
                    @change="reloadPostList"
                  ></v-date-picker>
                </v-menu>
              </span>
              <v-btn depressed text x-small color="default" class="mt-1 text-caption ml-1" @click="changeDate('next')" >
                  <v-icon>{{ icons.mdiChevronRight }}</v-icon>
              </v-btn>       
          </div>
        </v-col>

        <v-col cols="12"  md="7" sm="6" xs="12">
            <div class="d-flex justify-start align-left">
                <v-btn-toggle
                      v-model="params.authorFilter"
                      color="primary"
                      class="ml-2"
                      hide-details
                      dense
                      @change="reloadPostList"
                  >

                    <v-btn small dense depressed value="DOMAIN" class="d-flex align-center">
                      <v-icon size="14" color="primary" class="mr-1 d-none d-sm-none d-md-block">{{ icons.mdiWeb }}</v-icon>
                      <span class="text-subtitle-2">DOMAIN</span>
                    </v-btn>

                    <v-btn small depressed value="ENS">
                      <v-icon size="14" color="secondary" class="mr-1 d-none d-sm-none d-md-block">{{ icons.mdiCheckCircleOutline }}</v-icon>
                      ENS
                    </v-btn>

                    <v-btn small depressed value="REPUTATION">
                      <v-icon size="14" color="success" class="mr-1 d-none d-sm-none d-md-block">{{ icons.mdiEmoticonHappy }}</v-icon>OK
                    </v-btn>
              </v-btn-toggle>

            </div>
        </v-col>
      </v-row>

      <v-row class="px-3 ">
        <v-col cols="12" md="6" lg="4" sm="12" class="mb-3 d-flex justify-start align-center" >
          <v-text-field
            v-model="params.author"
            placeholder="Search by address"
            hide-details
            dense
            outlined
            clearable
            @change="reloadPostList"
          >
          </v-text-field>
        </v-col>
      </v-row>

      <v-row class="mt-0 mb-2 px-2 text-caption" v-if="params.author">
          <v-col
            cols="12"
            sm="12"
            class="d-flex align-center justify-start"
            >
          <v-btn
            outlined
            small dense 
            class="my-auto"
            :loading="batchLoading"
            @click="hideAllPostByAuthor(params.author)"
          >
            <v-icon class="me-2" size="16" color="error">{{ icons.mdiEmoticonDead }}</v-icon>
            Hide All
          </v-btn>

          <v-divider vertical class="mx-2"></v-divider>

          <!-- add a btn clear Author filter and reload -->
          <v-btn small dense outlined class="my-auto" @click="clearAuthorFilter">
            <v-icon class="me-2" size="16" color="primary">{{ icons.mdiCloseCircle }}</v-icon>
            CLEAR FILTER
          </v-btn>

        </v-col>

        <v-col cols="12" sm="12" class="d-flex align-center justify-start">
          <div class="d-block align-center justify-start" v-if="authorTags">
          <span class="text-caption secondary--text mr-4">AUTHORS:</span>
              <v-chip
                v-for="(ctag,index) in authorTags"
                small
                outlined
                :key="`hot-${index}`"
                :color="pickedTags.find((tag)=>tag.text===ctag.name)?'error':'secondary'"
                class="mr-4"
                @click="addTag(ctag.name)"
            >{{ctag.cn}}</v-chip>
          </div>
          </v-col>
      </v-row>

      <v-progress-linear
            indeterminate
            v-show="requestLoading"
            color="primary"
            class="mt-0"
      ></v-progress-linear>

      <v-skeleton-loader
                type="list-item-three-line, list-item-three-line, list-item-three-line"
                :loading="true"
                v-if="!postList"
      >
      </v-skeleton-loader>
      <template v-else>
          <v-simple-table class="mt-0 d-none d-sm-none d-md-block">
            <thead>
                <tr>
                    <th v-for="(item, index) in listHeaders" :key="index" class="text-no-wrap text-body-2 font-weight-black">{{item}}</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in postList" :key="item.postId">
                    <td  style="width:140px;height:75px;">
                      <div class="d-flex align-center justify-start">
                          <div class="text-subtitle-1" v-if="item.postId">
                            <a :href="`https://mirror.xyz/${item.contributor}/${item.digest}`" target="_blank">{{item.postId}}</a>
                          </div>
                          <v-icon class="ml-1" v-if="item.cover" size="14" color="primary">{{
                            icons.mdiImage
                            }}</v-icon>
                        </div>
                    </td>

                    <td style="max-width:120px">
                      <div class="d-flex align-center justify-start"  v-if="item.author">
                          <v-icon v-if="item.author.domain" size="14" color="success" class="mr-1">
                              {{icons.mdiWeb}}
                          </v-icon>
                          <v-icon v-else-if="item.author.ens" size="14" color="success" class="mr-1">
                              {{icons.mdiCheckCircleOutline}}
                          </v-icon>
                          <div class="text-subtitle-1 text-truncate">
                            <a :class='reputationColors[item.author.reputation] + "--text"' @click="editAuthor(item.author)">
                              {{item.author.displayName}}
                            </a>
                          </div>
                          <v-btn icon small class="ml-1 mt-1" @click="(params.author = item.author.address);reloadPostList();" >
                            <v-icon size="16" color="warning">{{icons.mdiFilterOutline}}</v-icon>
                          </v-btn>
                        </div>
                    </td>

                    <td style="max-width:120px">
                      <div class="d-flex flex-column align-start justify-start text-truncate">

                        <span class="text-body-1">
                          {{item.language}}
                          <v-icon v-if="params.author &&  pickedTags.length > 0"
                            color="success"
                            class="mr-2"
                            size="18"
                            @click="auditPost(item.digest,'SHOW')"
                          > {{ icons.mdiEmoticonHappy }}
                        </v-icon>
                        </span>
                        <span class="text-caption mt-2 info--text"><timeago :datetime="$tools.formatTimestamp(item.timestamp)" :auto-update="60"></timeago></span>
                      </div>
                    </td>

                    <td style="max-width:320px" class="cursor-pointer" @click="editPost(item.postId)">
                      <div class="d-flex flex-column align-start justify-start text-truncate" translate="yes">
                        <span class="text-body-2 text-truncate success--text" v-if="(item.featured == 1)">{{item.title}}</span>
                        <span class="text-body-2 text-truncate error--text" v-else-if="(item.featured == -1)">{{item.title}}</span>
                        <span class="text-body-2 text-truncate secondary--text" v-else>
                        {{item.title}}
                        </span>
                        <span class="text-caption primary--text mt-3">

                          <v-icon size="12" color="primary" class="mb-1 mr-1" v-if="item.tags">{{icons.mdiTagOutline}}</v-icon>
                          {{item.tags||'no tags'}}
                          </span>
                      </div>
                    </td>

                    <!--actions-->
                    <td>
                      <v-icon
                        class="me-2"
                        @click="editPost(item.postId)"
                      >
                        {{ icons.mdiPencilOutline }}
                      </v-icon>

                      
                        <v-icon color="info" class="me-2" @click="auditPost(item.digest,'TBC')">
                            {{ icons.mdiEmoticonNeutral }}
                        </v-icon>

                        <v-icon
                            color="error"
                            class="me-2"
                            @click="auditPost(item.digest,'HIDE')"
                          >
                            {{ icons.mdiEmoticonDead }}
                        </v-icon>
                    </td>

                </tr>
            </tbody>
        </v-simple-table>
        <!-- mobile show-->
        <div class="d-block d-sm-block d-md-none">

          <div class="px-3 my-2 text-caption" v-if="params.author &&  pickedTags.length > 0">
            <v-checkbox
              :value="selectAll"
              x-small
              hide-details
              class="text-caption"
              @change="toggleAll"
              :label= 'selectAll? "Unselect All" : "Select All " + postList.length'
            ></v-checkbox>
          </div>

          <div class="mt-2 px-3 " v-for="(item, index) in postList" :key="index" >
            <v-card  outlined elevation="0" rounded="false"  class="d-flex flex-column align-left post-list-item px-2 py-1" 
            :class='item.featured == 1 ? "featured" : item.featured == -1 ? "rejected" : "normal"'
            v-if="item.author">        
                  <div class="d-flex align-center justify-space-between">
                      <div class="d-flex justify-start align-center">
                          <v-icon v-if="item.author.domain" size="14" color="success" class="mr-1">
                              {{icons.mdiWeb}}
                          </v-icon>
                          <v-icon v-else-if="item.author.ens" size="14" color="success" class="mr-1">
                              {{icons.mdiCheckCircleOutline}}
                          </v-icon>
                          <div class="text-subtitle-1 text-truncate">
                          <a :class='reputationColors[item.author.reputation] + "--text"' @click="editAuthor(item.author)">
                              {{item.author.displayName}}
                          </a>
                          </div>
                          <v-btn icon x-small class="ml-1 mt-1" @click="(params.author = item.author.address);reloadPostList();" >
                              <v-icon size="14" color="warning">{{icons.mdiFilterOutline}}</v-icon>
                          </v-btn>
                      </div>
                      <span class="grey--text text-caption font-inter-regular ">
                          <timeago :datetime="$tools.formatTimestamp(item.timestamp)" :auto-update="60"></timeago>
                      </span>
                  </div>

                  <div class="font-weight-bold text-body-2 my-3 d-flex justify-start align-center" @click="editPost(item.postId)">
                    <v-icon class="mr-1" v-if="item.cover" size="14" color="primary">{{
                      icons.mdiImage
                      }}</v-icon>
                      <span class="text-body-2 success--text" v-if="(item.featured == 1)">{{item.title}}</span>
                      <span class="text-body-2 error--text" v-else-if="(item.featured == -1)">{{item.title}}</span>
                      <span class="text-body-2 secondary--text" v-else>{{item.title}}</span>
                  </div>

                <v-divider class="my-2"></v-divider>
                <div class="d-flex justify-start align-center">

                  <v-checkbox
                    class="mr-1 mb-1"
                    size="24"
                    hide-details
                    :key="item.digest"
                    color="success"
                    :value="item.digest"
                    v-model="batchShowDigests"
                    v-if="params.author &&  pickedTags.length > 0"
                  ></v-checkbox>


                  <v-icon v-if="params.author &&  pickedTags.length > 0" color="success" class="me-4" @click="auditPost(item.digest,'SHOW')"> {{ icons.mdiEmoticonHappy }}
                  </v-icon>
                  <v-icon class="me-4" @click="editPost(item.postId)">
                      {{ icons.mdiPencilOutline }}
                  </v-icon>    
                  <v-icon color="info" class="me-4" @click="auditPost(item.digest,'TBC')">
                      {{ icons.mdiEmoticonNeutral }}
                  </v-icon>
                  <v-icon color="error" class="me-4" @click="auditPost(item.digest,'HIDE')">
                      {{ icons.mdiEmoticonDead }}
                  </v-icon>
                </div>

                <v-divider class="my-2"></v-divider>
                  
                <div class="d-flex justify-space-between align-center" v-if="item.tags">
                    <div class="d-flex justify-start">
                        <span class="text-caption primary--text">
                              <v-icon size="12" color="primary" class="mb-1 mr-1">{{icons.mdiTagOutline}}</v-icon>
                              {{item.tags||'no tags'}}
                        </span>
                    </div>
                  </div> 
              </v-card>
            </div>
            <div v-if="batchShowDigests && batchShowDigests.length > 0" class="d-flex justify-space-start align-center my-4 px-3">
                <v-btn small class="mr-3" color="success" @click="auditPostBatch('SHOW')">
                    Show Selected
                </v-btn>
                <v-btn small class="mr-3" color="error" @click="auditPostBatch('HIDE')">
                    Hide Selected
                </v-btn>
            </div>
        </div>
        
    </template>

    <v-divider></v-divider>
    <div class="my-8" v-if="pageNum > 1">
        <v-pagination v-model="params.page" circle :total-visible="8" :length="pageNum" @input="changeRouterUrl" 
        class=""></v-pagination>
    </div>
    </v-card>


  <v-dialog
    v-model="isAuthorDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    translate="no"
  >
    <v-card tile v-if="authorInfo">
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="isAuthorDialog = false"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
        <v-toolbar-title class="text-body-1">
          <a :href="'https://mirror.xyz/' + authorInfo.address"  class="text-body-1 white--text" target="_blank">
            https://mirror.xyz/{{authorInfo.address}}
          </a>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            class="my-auto"
            @click="setAuthorQuickReputation(authorInfo.address,'OK')"
          >
            <v-icon class="me-2" color="success">{{ icons.mdiEmoticonHappy }}</v-icon>
            Mark AS OK
          </v-btn>
        </v-toolbar-items>
        <v-toolbar-items>
          <v-btn
            dark
            text
            class="my-auto"
            @click="setAuthorQuickReputation(authorInfo.address,'SPAM')"
          >
            <v-icon class="me-2" color="error">{{ icons.mdiEmoticonDead }}</v-icon>
            Mark AS SPAM
          </v-btn>
        </v-toolbar-items>
        <v-toolbar-items>
          <v-btn
            dark
            text
            class="my-auto"
            @click="setAuthorQuickReputation(authorInfo.address,'NEUTRAL')"
          >
            <v-icon class="me-2" color="info">{{ icons.mdiEmoticonNeutral }}</v-icon>
            Mark AS NEUTRAL
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-progress-linear
            indeterminate
            v-show="iframeLoading"
            color="primary"
            class="mt-0"
      ></v-progress-linear>
      
      <!-- v-card-text full-height -->
      <v-card-text class="mt-5" style="height:100%;">
        <iframe id="bdIframe" 
        @load="iframeLoading = false"
        :src='("https://mirror.xyz/" + authorInfo.address)' style="width:100%;height:100%;border:none;"></iframe>
      </v-card-text>
    </v-card>
  </v-dialog>


  <v-dialog
    v-model="isPostDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card tile v-if="postInfo" translate="no">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="isPostDialog = false" ><v-icon>{{ icons.mdiClose }}</v-icon></v-btn>
        <v-toolbar-title class="text-body-1">
          <a :href="`https://mirror.xyz/${postInfo.contributor}/${postInfo.digest}`" translate="yes" :key="postInfo.postId" class="text-body-1 white--text" target="_blank">{{postInfo.title}}</a>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            dark
            text
            class="my-auto"
            @click="auditPost(postInfo.digest,'SHOW')"
          >
            <v-icon class="me-md-2" color="success">{{ icons.mdiEmoticonHappy }}</v-icon>
            <span class="d-none d-sm-none d-md-block">SHOW [S]</span>
          </v-btn>
        </v-toolbar-items>
        <v-toolbar-items>
          <v-btn
            dark
            text
            class="my-auto"
            @click="auditPost(postInfo.digest,'HIDE')"
          >
            <v-icon class="me-md-2" color="error">{{ icons.mdiEmoticonDead }}</v-icon>
            <span class="d-none d-sm-none d-md-block">HIDE [H]</span>
          </v-btn>
        </v-toolbar-items>
        <v-toolbar-items>
          <v-btn
            dark
            text
            class="my-auto"
            @click="auditPost(postInfo.digest,'TBC')"
          >
            <v-icon class="me-md-2" color="info">{{ icons.mdiEmoticonNeutral }}</v-icon>
            <span class="d-none d-sm-none d-md-block">UNCERTAIN [U]</span>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      
      <!-- v-card-text full-height -->
      <v-card-text class="mt-5" style="height:100%;">
        <div class="text-body-1 font-weight-bold mb-4 d-flex justify-space-between">
          <div>
          Add tags to this post : <span class="text-caption info--text ml-2 d-none d-sm-none d-md-block">[S to show, H to hide, U to uncertain]</span>          
          </div>
          <a href="/tag"  class="primary--text" target="_blank">
            + ADD NEW TAG
          </a>
        </div>
        <vue-tags-input
            v-model="pickedTag"
            :tags="pickedTags"
            id="tagInput"
            class="py-3"
            :autocomplete-items="filteredTags"
            :add-on-key="[13, ',']"
            placeholder="Comma separated. example: DEFI, BTC"
            @before-adding-tag="beforeTagAdd"
            @tags-changed="tagsChanged"
        />
        
        <div class="d-block align-center justify-start mb-1">
          <span class="text-caption secondary--text mr-4">HOT TAGS:</span>
          <v-chip
            v-for="(ctag,index) in hotTags"
            small
            outlined
            :key="`hot-${index}`"
            :color="pickedTags.find((tag)=>tag.text===ctag.name)?'error':'secondary'"
            class="mr-4 mb-2"
            @click="addTag(ctag.name)"
          >{{ctag.cn}}</v-chip>
        </div>

        <div class="d-block align-center justify-start mt-4" v-if="authorTags">
          <span class="text-caption secondary--text mr-4">AUTHORS:</span>
          <v-chip
            v-for="(ctag,index) in authorTags"
            small
            outlined
            :key="`hot-${index}`"
            :color="pickedTags.find((tag)=>tag.text===ctag.name)?'error':'secondary'"
            class="mr-4 mb-2"
            @click="addTag(ctag.name)"
        >{{ctag.cn}}</v-chip>
        </div>
        <v-divider class="my-md-4 my-2"></v-divider>
        <div class="text-caption text--disabled mt-0 ">
            <v-card>
              <v-card-text>
                  <v-chip
                      v-for="(ctag,index) in allTags"
                      small
                      :key="index"
                      :color="pickedTags.find((tag)=>tag.text===ctag.text)?'error':'secondary'"
                      class="mr-4 mb-5"
                      @click="addTag(ctag.text)"
                  >{{ctag.cn}}</v-chip>
              </v-card-text>
              <v-card-actions>
                
                    <v-btn
                    class="mr-md-4 my-auto"
                      color="success"
                      outlined
                      small
                      @click="auditPost(postInfo.digest,'SHOW')"
                    >
                      <v-icon class="me-md-2 d-none d-sm-none d-md-block">{{ icons.mdiEmoticonHappy }}</v-icon>
                      SHOW [S]
                    </v-btn>
                 
                    <v-btn
                      color="error"
                      outlined
                      class="mr-md-4 my-auto"
                      small
                      @click="auditPost(postInfo.digest,'HIDE')"
                    >
                      <v-icon class="me-2 d-none d-sm-none d-md-block">{{ icons.mdiEmoticonDead }}</v-icon>
                      HIDE [H]
                    </v-btn>
                  
                    <v-btn
                      color="info"
                      outlined
                      class="my-auto"
                      small
                      @click="auditPost(postInfo.digest,'TBC')"
                    >
                      <v-icon class="me-md-2 d-none d-sm-none d-md-block">{{ icons.mdiEmoticonNeutral }}</v-icon>
                      UNCERTAIN [U]
                    </v-btn>
              </v-card-actions>
          </v-card>
        </div>

        <v-card class="text-body-1 my-2 pa-3 " v-if="latestAuthorPost">
          <div class="text-caption mt-0 ">
            <span class="text-caption secondary--text mr-4">Pre Post:</span>
            <div class="font-weight-bold my-2">{{latestAuthorPost.title}}</div>
            <div class="my-2 primary--text">{{ latestAuthorPost.tags }}</div>
            
            <div class="text-caption text--disabled mt-0 ">
              <timeago :datetime="$tools.formatTimestamp(latestAuthorPost.timestamp)" :auto-update="60"></timeago>
            </div>
          </div>
        </v-card>

        <div class="text-body-1 mb-4  px-2" v-if="postAuthor && !postAuthor.twitter">
          <v-row class="mt-6 px-md-5" >
            <v-col col="12" md="5" class="d-flex justify-start align-center ">
              <v-text-field prefix="https://twitter.com/" 
                class="text-body-1"  
                dense 
                outlined
                hide-details
                :rules="[twitterRules]"
                placeholder="set author's twitter"
                v-model="postAuthorTwitter">
              </v-text-field>
              <v-btn
                  color="success"
                  small
                  outlined
                  class="my-auto ml-2"
                  @click="saveAuthorTwitter">
                  <v-icon>{{ icons.mdiContentSave }}</v-icon>
                </v-btn>
            </v-col>
          </v-row>
        </div>
        
        <v-divider class="my-4"></v-divider>

        <div class="text-body-1 mt-4">
           <v-card class="mx-auto px-3" outlined  elevation="1" style="max-widht:1024px!important;" >
            <v-card-title class="text-h6 font-weight-bold" >
              <span :key="postInfo.postId" translate="yes">{{postInfo.title}}</span>
            </v-card-title>
            <v-card-subtitle class="text-body-2 my-1">
              <v-icon size="13" v-if="postInfo.author.domain" class="mr-1 mb-1" color="success">{{ icons.mdiDomain }}</v-icon>
              <v-icon size="13" v-else-if="postInfo.author.ens" class="mr-1 mb-1" color="success">{{ icons.mdiCheckCircleOutline }}</v-icon>
              <span>
                {{postInfo.author.displayName}}
              </span>
              <span class="text-caption ml-2 info--text">
                <timeago :datetime="$tools.formatTimestamp(postInfo.timestamp)" :auto-update="60"></timeago>
              </span>
            </v-card-subtitle>
            <v-card-text class="text-body-1 mt-4">
              <div v-if="postInfo.cover" class="text-left my-3">
                <img :src="JSON.parse(postInfo.cover).src" class="mx-auto" style="max-width:100%;max-height:300px;">
              </div>
              <div v-if="postInfo.body" translate="yes" class="text-body-2 secondary--text mt-2 markdown-body" v-html="markdownToHtml(postInfo.body)"></div>
            </v-card-text>
            </v-card>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import {mdiAccountOutline,mdiTwitter,mdiFilterOutline,
        mdiChevronLeft,mdiChevronRight,mdiContentSave,
        mdiCalendar,mdiWeb,mdiRefresh,mdiTagOutline,mdiEmoticonNeutral,mdiEmoticonHappy,
        mdiEmoticonDead,mdiCloseCircle,mdiPencilOutline,mdiClose,mdiImage,mdiCheckCircleOutline} from '@mdi/js';
import { authorReputations,authorSetReputation,authorGet,authorSetProfile } from '../request/author'
import { postList,postAudit,postAuditByAuthor} from '../request/post'
import { tagList,tagHotTags,tagAuthorTags } from '../request/tag'
import VueTagsInput from '@johmun/vue-tags-input';
import {marked} from "marked";
export default {
  components: {
    VueTagsInput
  },
  data() {
    return {
      batchShowDigests: [],
      latestAuthorPost: null,
      icons: {
        mdiTwitter,
        mdiCloseCircle,
        mdiAccountOutline,
        mdiEmoticonDead,
        mdiPencilOutline,
        mdiWeb,
        mdiEmoticonHappy,
        mdiClose,
        mdiImage,
        mdiFilterOutline,
        mdiEmoticonNeutral,
        mdiCheckCircleOutline,
        mdiRefresh,
        mdiTagOutline,
        mdiCalendar,
        mdiChevronLeft,
        mdiChevronRight,
        mdiContentSave
      },
      listHeaders: [
        "POSTID","AUTHOR","LANG","TITLE","ACTIONS"
      ],
      postList: null,
      postInfo: null,
      authorInfo: null,
      postAuthor:null,
      postAuthorTwitter:null,
      languageOptions: [
        { code: 'zh', name: '中文简体' },
        { code: 'en', name: 'ENGLISH' },
        { code: 'zh-Hant', name: '中文繁體' },
        { code: 'ja', name: '日本語' },
        { code: 'ko', name: '한국어' },
        { code: 'ru', name: 'русский' },
        { code: 'id', name: 'Bahasa Indonesia' }
      ],
      params: {
        auditStatus: 0,
        featured: null,
        language: 'zh',
        author: null,
        authorFilter: 'REPUTATION',
        hasEns:null,
        hasDomain:null,
        reputationOk:1,
        startDate: new Date(new Date().getTime() - 1 *24 * 60 * 60 * 1000 ).toISOString().substr(0, 10),
        endDate: new Date().toISOString().substr(0, 10),
        page:1,
        size:50
      },

      twitterRules: (v) => {
        if (v && v.length > 0) {
            v = v.replace(/https:\/\/(www\.)?twitter\.com\//, '');
            this.postAuthorTwitter = v;
            return true;
        }
        return true
      },

      authorTags: null,

      reputationColors: {
        0: 'secondary',
        1: 'secondary',
        2: 'success',
        3: 'warning',
        4: 'error',
        5: 'error',
      },

      reputationName : {
        0: 'UNKNOWN',
        1: 'NEUTRAL',
        2: 'OK',
        3: 'SUSPICIOUS',
        4: 'UNSAFE',
        5: 'SPAM',
      },
      pageNum: 1,
      totalCount:0,
      isPostDialog:false,
      isAuthorDialog:false,
      iframeLoading:false,
      requestLoading:false,
      allTags: [],
      hotTags: [],
      pickedTags:[],
      pickedTag:"",
      batchLoading:false,
      curPostId:null,
      startTimeMenu: false,
      endTimeMenu: false,
    }
  },
  computed: {
    selectAll() {
       return this.batchShowDigests && this.postList &&  this.batchShowDigests.length === this.postList.length && this.batchShowDigests.length > 0;
    },
    filteredTags() {
      let tags =  this.allTags.filter(i => {
        return i.text.toLowerCase().indexOf(this.pickedTag.toLowerCase()) !== -1;
      });
      return tags;
    },
  },
  watch: {
    '$route' (to, from) { 
       this.getPostList();
	  }
  },
  created() {
  },
  mounted() {
      //get params default value from url
      let params = this.$route.query;
      let intKeys = ['auditStatus','featured','hasEns','hasDomain','reputationOk','page','size'];
      for(let key in params) {
        if(this.params[key] !== undefined) {
            if(intKeys.indexOf(key) !== -1) {
              this.params[key] = parseInt(params[key]);
            } else {
              this.params[key] = params[key];
            }
        }
      }

      this.setAuthorFilter();

      this.getAuthorReputations();
      this.getPostList();
      this.getTagList();
      this.registShortKeys();
  },


  methods: {

    toggleAll() {
      if (this.batchShowDigests.length) {
          this.batchShowDigests = [];
      } else {
          this.batchShowDigests = this.postList.map(i => i.digest);
      }
    },

    changeDate(type) {
      if(type == 'prev') {
        // start day -1  end day -1
        let startDate = new Date(this.params.startDate);
        let endDate = new Date(this.params.endDate);
        let diff = endDate.getTime() - startDate.getTime();
        this.params.startDate = new Date(startDate.getTime() - diff).toISOString().substr(0, 10);
        this.params.endDate = new Date(endDate.getTime() - diff).toISOString().substr(0, 10);
      } else {
        let startDate = new Date(this.params.startDate);
        let endDate = new Date(this.params.endDate);
        let diff = endDate.getTime() - startDate.getTime();
        this.params.startDate = new Date(startDate.getTime() + diff).toISOString().substr(0, 10);
        this.params.endDate = new Date(endDate.getTime() + diff).toISOString().substr(0, 10);
      }
      this.reloadPostList();
    },

    onAuditStatusChange() {
      this.params.featured = (this.params.auditStatus === 0 ? 0 : 1);
      this.reloadPostList();
    },
  
    tagsChanged(tags) {
       this.pickedTags = tags;
    },

    clearAuthorFilter() {
      this.params.author = null;
      this.batchShowDigests=[];
      this.reloadPostList();
    },

    async saveAuthorTwitter() {
      if(!this.postAuthorTwitter) {
        this.$q.error('Twitter is empty');
        return;
      }
      this.postAuthorTwitter = this.postAuthorTwitter.trim();
      
      await authorSetProfile({address: this.postAuthor.address, twitter: this.postAuthorTwitter}).catch(err => {
          this.$q.error(err);
        }).then(res => {
        if(res.status == 0){
          this.$q.success("SAVE SUCCESS");
          this.postAuthor.twitter = this.postAuthorTwitter;
        }else{
          this.$q.error("SAVE FAILED");
        }});
    },

    beforeTagAdd(obj) {
      if(this.pickedTags.length >= 5) {
        this.$q.error('Only 5 tags can be added at most');
        return false;
      }

      //transform tag to upper case
      obj.tag.text = obj.tag.text.toUpperCase();
      obj.addTag();
    },

    async registShortKeys() {
      
      document.onkeydown = async (e) => {
          // if current page not in /post ,return 
          if(this.$route.path !== '/post') return;
          //#textEdit is focus,return
          if(document.activeElement.id === 'tagInput') 
            return;
          
            //if input is focus,return
          if(document.activeElement.tagName === 'INPUT')
              return;

          if(e.key.toLowerCase() == 'h' && this.postInfo) {
              await this.auditPost(this.postInfo.digest,'HIDE');
              this.autoSelectPost('DOWN');
          } else if(e.key.toLowerCase() == 's' && this.postInfo) {
              await this.auditPost(this.postInfo.digest,'SHOW');
              this.autoSelectPost('DOWN');
          } else if(e.key.toLowerCase() == 'u' && this.postInfo) {
              await this.auditPost(this.postInfo.digest,'TBC');
              this.autoSelectPost('DOWN');
          } else if(e.key.toLowerCase() == 'j'  || e.key.toLowerCase() == 'arrowdown') {
              this.autoSelectPost('DOWN');
          } else if(e.key.toLowerCase() == 'k' || e.key.toLowerCase() == 'arrowup') {
              this.autoSelectPost('UP');
          }  else if(e.key.toLowerCase() == 'r') {
              this.getPostList();
          } 
      }
    },

    async autoSelectPost(direction) {
       
        if(!this.curPostId) {
            return;
        }
        let postId = this.curPostId;
        let index = this.postList.findIndex(i => i.postId == postId);

        if(index == -1) {
            return;
        }
        
        let postInfo = null;
        if(direction == 'UP') {
           //find the one auditStatus = 0
            let nextIndex = index - 1;
            while(nextIndex >= 0) {
                if(this.postList[nextIndex].auditStatus == 0) {
                    postInfo = this.postList[nextIndex];
                    break;
                }
                nextIndex--;
            }
        } else if(direction == 'DOWN') {
            let nextIndex = index + 1;
            while(nextIndex < this.postList.length) {
                if(this.postList[nextIndex].auditStatus == 0) {
                    postInfo = this.postList[nextIndex];
                    break;
                }
                nextIndex++;
            }
        }

        if(postInfo) {          
            //delay 100ms
            setTimeout(() => {
              this.editPost(postInfo.postId);
            },100);
        }else {
            this.$q.info('No more post need to audit in this page');
        }
    },

    async reloadPostList() {
        this.params.page = 1;
        this.batchShowDigests = [];
        this.changeRouterUrl();
    }, 
    addTag(tag){
      if(!this.pickedTags.find(i => i.text == tag)) {

          if(this.pickedTags.length >= 5) {
              this.$q.error('Only 5 tags can be added at most');
              return false;
          }
          this.pickedTags.push({text:tag});
      }else {
          //remove
          this.pickedTags = this.pickedTags.filter(i => i.text != tag);
      }
    },

    async auditPostBatch(status) {
       if(this.batchShowDigests.length == 0) {
          this.$q.error('Please select posts first');
          return;
       }

       for(let i = 0; i < this.batchShowDigests.length; i++) {
          await this.auditPost(this.batchShowDigests[i],status,false);
       }

       this.batchShowDigests = [];

       this.$q.success('Audit success');
    },

    async auditPost(digest,status,showTip=true){
      var params = {
        digest:digest,
        auditResult:status,
      }

      var tags = null;
      if(status == 'SHOW'){
        if(this.pickedTags.length == 0) {
          // this.$q.error('ADD TAGS FIRST!');
          // return;
          params.tags = '';
        }else {
          //this.pickTags = [{text:'TAG1'},{text:'TAG2'}], need to transform to 'TAG1,TAG2'
          tags = this.pickedTags.map(i => i.text).join(',');
          params.tags = tags;
        }
      }

      let res = await postAudit(params);
      if(res.status == 0){
        if(showTip) {
          this.$q.success("Audit success");
        }

        //set postInfo
        let postInfo  = this.postList.find(i => i.digest == digest);
        if(postInfo){
            postInfo.auditStatus = status;
            if(status == 'SHOW'){
                postInfo.featured = 1;
                postInfo.tags = tags;
            } else if(status == 'HIDE'){
              postInfo.featured = -1;
            }
        }

      }else{
        this.$q.error("Audit failed");
      }

      this.isPostDialog = false;
    },

    markdownToHtml(content){
          var render = new marked.Renderer();
            render.link = function(href, title, text) {
                
                if(text == 'subscribe://'){
                    var subscribeBtn = `<a class="subscribe-on-mirror-btn" href="javascript:void(0);"> + Subscribe on Mirror</a>`;
                    return subscribeBtn;
                }

                return `<a href="${href}" target="_blank" title="${title}">${text}</a>`;
            };

            marked.setOptions({renderer: render,breaks: true});
            var html =  marked.parse(content);
            return html;
    },

    async getTagList() {
      const res = await tagList();
      if (res.status === 0) {
        //sort by tag name
        res.data.sort((a,b) => {
          return a.cn.localeCompare(b.cn);
        });
        res.data.forEach((item) => {
          this.allTags.push({"text":item.name,"cn":item.cn});
        });
      }

      //hotTags
      const hotRes = await tagHotTags();
      if (hotRes.status === 0) {
        this.hotTags = hotRes.data;
      }
    },

    async hideAllPostByAuthor(author) {

        //add confirm
        if(!confirm("Are you sure to hide all posts by this author?")){
          return;
        }

        this.batchLoading = true;
        await postAuditByAuthor({author:author,auditResult:'HIDE'}).catch(err => {
          this.$q.error(err);
        }).then(res => {
          if(res.status == 0){
            this.$q.success("Hide all post by author success");
            //set all data to hide
            this.postList.forEach((item) => {
              if(item.author == author){
                item.auditStatus = 1;
                item.featured = -1;
              }
            });
          }else{
            this.$q.error("Hide all post by author failed");
          }});    
        this.batchLoading = false;
    },
    async setAuthorQuickReputation(address,reputation){
      let reputationCode =  this.reputationOptions.find(item => item.name === reputation).code;
      
      //get name by reputation code
      await authorSetReputation({address:address,reputation:reputationCode}).then(res=>{
          this.$q.success('Set Reputation Success');

          //change all post's author reputation
          this.postList.forEach(item => {
            if(item.contributor === address){
              item.author.reputation = reputationCode;
            }
          });
      }).catch(err=>{
          this.$q.error(err);
      })

      this.isAuthorDialog = false;
    },

    editPost(postId) {
      this.postInfo = this.postList.find(item => item.postId === postId);
      this.curPostId = this.postInfo.postId;
      this.pickedTag = '';
      this.pickedTags = this.postInfo.tags ? this.postInfo.tags.split(',').map(i => {return {text:i}}) : [];
      this.isPostDialog = true;

      this.getAuthorTags(this.postInfo.contributor);

      //get author info
      this.getAuthorInfo(this.postInfo.contributor);
    },

    editAuthor(authorInfo) {
        this.authorInfo = authorInfo;
        this.isAuthorDialog = true;
        this.iframeLoading = true;
      
      setTimeout(function() {
            const oIframe = document.getElementById("bdIframe");
            const deviceWidth = document.documentElement.clientWidth;
            const deviceHeight = document.documentElement.clientHeight;
            oIframe.style.width = Number(deviceWidth);
            oIframe.style.height = Number(deviceHeight) - 120 + "px"; 
        }, 1000);
    },  

    setAuthorFilter(){ 
      this.params.hasDomain = null;
      this.params.hasEns = null;
      this.params.reputationOk = null;
    
      if(this.params.authorFilter == 'DOMAIN') {
          this.params.hasDomain = 1;
      } else if(this.params.authorFilter == 'ENS') {
          this.params.hasEns = 1;
      } else if(this.params.authorFilter == 'REPUTATION') {
          this.params.reputationOk = 1;
      } else {
          this.params.authorFilter = 'ALL';
      }
    },

    changeRouterUrl () {

      if(this.params.auditStatus == 0) {
          this.params.featured = 0;
      }
      
      this.setAuthorFilter();

      //dynamic replace url params which is not null
      let query = {};
      for(let key in this.params){
        if(this.params[key] != null){
          query[key] = this.params[key];
        }
      }
      this.$router.replace({path: this.$route.path, query: query});
    },

    async getAuthorInfo(address) {
      this.postAuthor = null;
      this.postAuthor = await authorGet({address:address}).then(res => {
        if(res.status == 0){
          this.postAuthorTwitter = res.data.twitter;
          return res.data;
        }else{
          return null;
        }
      }).catch(err => {
        return null;
      });

      //get latest author featured post limit 1
      this.latestAuthorPost = null;
      this.latestAuthorPost = await postList({author:address,featured:1,size:1}).then(res => {
        if(res.status == 0){
          return res.data.resultList.length > 0 ? res.data.resultList[0] : null;
        }else{
          return null;
        }
      }).catch(err => {
        return null;
      });

    }, 

    async getAuthorTags(address) {

      this.authorTags = null;
      this.authorTags = await tagAuthorTags({address:address}).then(res => {
        if(res.status == 0){
          return res.data;
        }else{
          return null;
        }
      }).catch(err => {
        return null;
      });
    },

    async getPostList() {
      this.requestLoading = true;
    
      await postList(this.params).then(res => {
          if (res.status === 0) {
            this.postList = res.data.resultList;
            var totalCount = res.data.totalCount;
            this.totalCount = totalCount;
            this.curPostId =  res.data.resultList.length > 0 ? res.data.resultList[0].postId : null;
            this.pageNum = totalCount > 0 ? ((totalCount < this.params.size) ? 1 : ((totalCount % this.params.size) ? (parseInt(totalCount / this.params.size) + 1) : parseInt((totalCount / this.params.size)))) : 0;
          }else {
            this.$q.error(res.message);
          }
      }).catch(err => {
        this.$q.error(err);
      });
      this.requestLoading = false;

      if(this.params.author) {
         this.getAuthorTags(this.params.author);
      }
    },

    async getAuthorReputations() {
      await authorReputations().then(res => {
        if (res.status == 0) {
          this.reputationOptions = res.data
        } else {
          this.$q.error(res.message)
        }
      }).catch(err => {
        this.$q.error('Failed to get data!')
        console.log(err)
      })
    },
  },
}
</script>

<style lang="scss">
@import '~@/styles/github-markdown.css';
.vue-tags-input {
    background: transparent!important;
    max-width: 100%!important;
    .ti-input {
        height: 56px!important;
        padding: 12px 8px!important;
        border: 1px solid rgba(94, 86, 105, 0.22)!important;
        border-radius: 5px;
    }

    .ti-tag, .ti-selected-item {
        background-color: rgb(210, 35, 35)!important;
    }

    .ti-item {
        padding: 8px;
    }
}

.v-input--selection-controls {
   margin-top: 0!important;
}

.date-picker .v-text-field__slot {
    max-width: 160px!important;
}

.v-input--selection-controls__ripple {
    display: none!important;
}

.markdown-body {
  max-width: 1024px !important;
}
.language-select {
  max-width: 130px!important;
}

.v-card.normal {
  border-color: #e0e0e0!important;
}

.v-card.featured {
  border-color: #24dc0b!important;
}

.v-card.rejected {
  border-color: #f72815!important;
}

</style>
